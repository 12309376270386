<template>
  <el-row type="flex" class="header" justify="space-between">
    <div @click="handleCollapse" class="header-collapse">
      <i style="font-size: 1.30208vw" :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
    </div>
    <el-row class="header-right" type="flex">
      <el-row type="flex" class="header-nav">
        <template v-for="item in businessList">
          <div :key="item.id" v-if="menuList.findIndex(menu=>menu.path===item.busiUrl)!==-1 || /http/.test(item.busiUrl)" :class="module === item.busiUrl ? 'active' : ''" @click="handleChangeModule(item)">{{ item.busiName }}</div>
        </template>
      </el-row>
      <!-- <div class="header-notice-wrap" @click="goNotice">
        <el-badge class="header-notice" :value="$store.state.notice ? $store.state.notice : '' ">
          <img :style="{ width: '1.25vw', height: '1.25vw' }" :src="noiceImg" alt="">
        </el-badge>
      </div> -->
      <el-avatar size="medium" :src="require('../assets/img/avatar.png')" class="header-avatar"></el-avatar>
      <el-dropdown>
        <span class="el-dropdown-link">
          <span style="cursor: pointer;line-height: 3.125vw">{{ userInfo.username }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleEdit">修改密码</el-dropdown-item>
          <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" append-to-body width="35%" left>
      <el-form label-width="4.16667vw" label-position="right" :model="pwdForm" :rules="rules" ref="pwdForm">
        <el-form-item label="原密码" prop="pwd">
          <el-input type="password" placeholder="请输入原登录密码" v-model="pwdForm.pwd"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input type="password" placeholder="请输入新的登录密码" v-model="pwdForm.newPwd"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="againPwd">
          <el-input type="password" placeholder="再次输入登录密码" v-model="pwdForm.againPwd"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="conserve">保存</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
import {mapState} from "vuex";
import {removeToken} from "@/utils/auth";
import watermark from "@/utils/watermark";
import { validNoContinue, validNoSame } from "@/utils/validate";

export default {
  props: ["module"],
  data() {
    var validatePass = (rule, value, callback) => {
      let rules1 = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,16}$/g
      if (value === "") {
        callback(new Error("请输入8-16位登录密码"));
      } else {
        if(!rules1.test(value)){
          callback(new Error("登录密码为8-16位且必须有数字、字母"));
        } else if(!validNoSame(value)){
          callback(new Error("登录密码不允许3个以上的重复字符"));
        } else if(!validNoContinue(value)){
          callback(new Error("登录密码不允许3个以上的连续字符"));
        } else {
          if (this.pwdForm.againPwd !== "") {
            this.$refs.pwdForm.validateField("againPwd");
          }
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (value !== this.pwdForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      noiceImg: require("@/assets/img/header-noice-icon.png"),
      isCollapse: false,
      businessList:[],
      dialogVisible: false,
      pwdForm: {
        pwd: '',
        newPwd: '',
        againPwd: ''
      },
      
      rules: {
        pwd: [
          { required: true, message: '请输入原登录密码', trigger: 'blur' },
        ],
        newPwd: [
          { min: 8, message: "密码长度不能小于8", trigger: "blur" },
          { required: true, message: '请输入8-16位新的登录密码', trigger: 'blur' },
          { validator: validatePass, trigger: "blur" },
        ],
        againPwd: [
          { required: true, message: '请再次输入新的登录密码', trigger: 'blur' },
          // { min: 6, max: 14, message: '长度在 6 到 14 个字符', trigger: 'blur' }
          { validator: validatePass2, trigger: "blur" }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      menuList: state => state.common.menuList,
      userInfo: state => state.userInfo
    })
  },
  mounted() {
  },
  methods: {
    goNotice(){
      this.$router.push('/newsCenter')
    },
    handleCollapse() {
      this.isCollapse = !this.isCollapse;
      this.$emit("collapse", this.isCollapse);
    },
    handleChangeModule(item) {
      if(/http/.test(item.busiUrl)){
        location.href=item.busiUrl
      }else {
        this.$router.push(item.busiUrl)
      }
    },
    // 退出登陆  个人资料
    handleLogout() {
      this.$confirm("确认退出, 是否继续?", {type: "warning",closeOnClickModal: false}).then(() => {
        localStorage.removeItem("userInfo")
        removeToken("x-access-token");
        watermark.set("")
        this.$router.push("/login");
        this.$message.success("退出成功!");
      })
    },
    // 修改密码
    handleEdit() {
      this.dialogVisible = true;
    },
    conserve() {
      this.$refs['pwdForm'].validate((valid) => {
        if (valid) {
          if (this.pwdForm.newPwd !== this.pwdForm.againPwd) {
              this.$message.warning('两次密码输入不一致')
              return;
          }
          this.$api.userEdit({ passwordOld: this.pwdForm.pwd, passwordNew: this.pwdForm.newPwd}).then(res => {
            if(res.data.data){
              this.$message.success('修改成功');
              this.dialogVisible = false;
            }else {
              this.$message.error(res.data.msg)
            }
          })
        }
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.$refs.pwdForm.resetFields();
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  height: 55px;
  padding-right: 32px;
  box-shadow: 0px 3px 6px rgba(156, 156, 156, 0.16);
  background: #597EF7;
  position: relative;
  z-index: 1;
  .header-collapse {
    user-select: none;
    cursor: pointer;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #4B72F3;
    }

    i {
      color: #FFFFFF;
      line-height: 55px;
    }
  }

  .header-right {
    align-items: center;
    .header-notice-wrap {
      width: 48px;
      height: 48px;
      cursor: pointer;

      .header-notice {
        width: 24px;
        height: 24px;
        margin: 12px;
        text-align: center;
        i{
          display: block;
          width: 20px;
          height: 20px;
          margin: 5px auto 0;
          font-size: 20px;
          color: #888;
        }
      }
    }

    .header-nav {
      div {
        padding: 0 20px;
        border-right: 1px solid #E8E8E8;
        font-size: 16px;
        font-weight: 400;
        line-height: 60px;
        color: #444;
        cursor: pointer;

        &:hover, &.active {
          color: #597EF7;
          font-weight: 600;
        }
      }
    }
    .header-avatar{
      margin:12px;
      cursor: pointer;
    }
    .el-dropdown{
      color: #FFFFFF;
    }
  }
}
</style>
