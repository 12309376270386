<template>
  <div class="layout-tab-wrap">
    <el-tabs v-model="activeName" type="card" @tab-click="handleTabsClick" class="tab-view" @tab-remove="handleClose">
      <el-tab-pane label="我的主页" :name="module">
        <span slot="label"><i class="tag-icon iconfont iconshouye" style="font-size: 0.9375vw"></i>欢迎页</span>
      </el-tab-pane>
      <el-tab-pane v-for="item in tagViewList" :key="item.path" :label="item.meta.title" closable
                   :name="item.fullPath">
        <span slot="label"><i v-if="item.meta.icon" :class="'tag-icon iconfont '+ item.meta.icon"></i>{{item.meta.title}}</span>
      </el-tab-pane>
    </el-tabs>
    <el-dropdown trigger="click" class="tag-setting">
      <el-button icon="el-icon-setting" size="small" class="tag-setting-icon"></el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="handleCloseAll">关闭全部</el-dropdown-item>
        <el-dropdown-item @click.native="handleCloseOther">关闭其他</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'

export default {
  props:['module'],
  data() {
    return {
      activeName: ''
    }
  },
  provide(){
    return {
        tagClose: (name) => { console.log(name); return this.handleClose(name) }
    }
  },
  mounted() {
    this.activeName=this.module
    this.AddTag()
  },
  computed: {
    ...mapState({
      tagViewList: state => state.tagViewList,
      menuList: state => state.common.menuList
    }),
  },
  watch: {
    $route() {
      this.AddTag()
    },
  },
  methods: {
    ...mapMutations(['setTagViewList']),
    AddTag() {
      console.log(this.$route.fullPath)
      console.log(this.tagViewList)
      if (this.$route.name !== 'index') {
        let route = this.$route
        // if(this.$route.fullPath != "/"){
          if(this.tagViewList.findIndex(item => (item.fullPath === this.$route.fullPath)) !== -1){
            //相同fullPath
          }else if(this.tagViewList.findIndex(item => (item.path === this.$route.path)) !== -1){
            //相同path
            this.tagViewList.splice(this.tagViewList.findIndex(item => (item.path === this.$route.path)),1)
            this.tagViewList.push(route)

          } else {
            //都不同
            this.tagViewList.push(route)
          }
          this.activeName = this.$route.fullPath
        // }
      } else {
        this.activeName=this.module
      }
    },
    handleTabsClick(tab) {
      if (this.$route.path !== tab.name) {
        this.$router.push(tab.name)
      }
    },
    handleClose(targetName) {
      console.log(targetName)
      let tabs = this.tagViewList;
      if (this.activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.fullPath === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              this.activeName = nextTab.name;
              this.$router.push(nextTab.fullPath)
            }
          }
        });
      }
      let newTagList = tabs.filter(tab => tab.fullPath !== targetName)
      this.setTagViewList(newTagList)
      if (!newTagList.length) {
        this.handleCloseAll()
      }
    },
    handleCloseAll() {
      this.setTagViewList([])
      console.log(this.module)
      this.$router.push("/")
    },
    handleCloseOther(){
      this.setTagViewList([this.$route])
    },
  }
}
</script>
<style lang="scss" scoped>
.tag-setting {
  position: absolute;
  top: 12px;
  right: 10px;
}

.layout-tab-wrap {
  position: relative;
  background: #fff;
}

.tag-icon {
  color: #597EF7;
  font-size: 16px;
  transform: translateY(2px);
  display: inline-block;
  margin-right: 4px;
}
</style>
<style lang="scss">
.tab-view {
  padding-top: 10px;
}

.tab-view .el-tabs__header {
  margin-bottom: 0 !important;
  padding-right: 60px;
}
.tab-view .el-tabs__item.is-active{
  background: #F5F6FA;
  border-bottom-color: #F5F6FA!important;
}
.tag-setting-icon{
  background: #F3F3F3;
  margin: 0;
}
.tab-view .el-tabs__nav{
  display: flex;
}
.tab-view .el-tabs__nav{
  display: flex;
}
.tab-view .el-tabs__item{
  display: block;
}
</style>
