<template>
  <el-container class="container">
    <el-aside :width="isCollapse ? '3.33333vw' : '10.72917vw'" class="layout-aside">
      <div class="layout-title-wrap" v-if="!isCollapse" @click="handleToHome">
        <!-- <img class="logo-img" src="../assets/img/jyjy-logo.png" alt=""> -->
        <div class="layout-title">青蓝STAR教师平台</div>
      </div>
      <div class="layout-title-wrap-small" v-else @click="handleToHome">
        <img class="logo-img-small" src="../assets/img/jyjy-logo.png" alt="">
      </div>
      <div class="menu-wrap" :style="`top: ${isCollapse ? '2.86458vw' : '2.86458vw'}`"><!--isCollapse ? '2.86458vw' : '2.86458vw'-->
        <Menu :isCollapse="isCollapse" :module="module"/>
      </div>
    </el-aside>
    <el-container :style="{width: isCollapse ? 'calc(100% - 3.33333vw)' : 'calc(100% - 10.72917vw)'}">
      <el-header class="layout-header">
        <Header :module="module" @collapse="getCollapse"/>
        <TagView :module="module" ref="tagView"/>
      </el-header>
      <el-main class="layout-main-wrap">
        <div class="layout-main">
          <div class="router-wrap">
            <div class="feedback-icon" @click="handleFeedBack">反馈</div>
            <el-backtop target=".router-wrap" v-if="$router.path != '/courseResource'" :bottom="120" :visibility-height="5"></el-backtop>
            <!-- <el-backtop target=".router-wrap" :bottom="100"></el-backtop> -->
            <Main></Main>
          </div>
        </div>
        <div class="copyright">
          <div>
            <span>Copyright </span>
            <i class="iconfont iconcopyright"></i>
            <span> www.qinglanyun.com, All Rights Reserved.</span>
          </div>
          <span style="font-size: 0.3vw;"> 教育，让生命更美好</span>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="反馈" :visible.sync="feedbackVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
      <el-form :model="feedbackForm" ref="feedbackForm" :rules="rules" label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="反馈类型：" prop="type" v-if="showTypeItem">
              <el-select class="form-input" v-model="feedbackForm.type" clearable placeholder="请选择反馈类型">
                <el-option v-for="item in feedbackTypeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="反馈内容：" prop="info">
              <el-input class="form-input feedback" type="textarea" :rows="5" placeholder="请输入内容" v-model="feedbackForm.info" resize="none" :maxlength="500" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item">
              <div class="choose-img-list">
                <div class="img-item" v-for="(item,i) in feedbackForm.imgs" :key="item">
                  <img :src="item" alt="">
                  <i class="el-icon-error delete-icon" @click="delFeedbackImg(i)"></i>
                </div>
                <el-upload ref="fileUpload" class="feedback-avatar-uploader" :action="uploadUrl" :headers="uploadHeader" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :accept="'.png,.jpg'">
                  <div class="feedback-avatar-uploader-info">
                    <i class="el-icon-plus feedback-avatar-uploader-icon"></i>
                    <div class="feedback-avatar-uploader-title">上传图片</div>
                  </div>
                </el-upload><!--v-loading.fullscreen.lock="uploadingFlag"-->
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit">保 存</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import Header from "@/layout/Header.vue";
import Menu from "@/layout/Menu";
import TagView from "@/components/TagView";
import Main from "@/layout/Main.vue";
import { getToken } from "@/utils/auth";
import { mapState } from "vuex";

export default {
  provide(){
    return {
        tagClose :(name) => this.$refs.tagView.handleClose(name)
    }
  },
  data() {
    return {
      list: [],
      menu: [],
      inputValue: "",
      isCollapse: false,
      module: '',
      fileTypeList: ["png", "jpg"], //文件类型list
      uploadUrl: (process.env.NODE_ENV == "development" ? process.env.VUE_APP_LOCAL_URL + "/webForm" : process.env.VUE_APP_LOCAL_URL) + "/api/upload/uploadImgToOSS", //上传地址
      uploadHeader: { token: this.getToken() },
      showTypeItem: true, //是否显示反馈类型

      feedbackVisible: false,// 反馈弹窗标识
      feedbackForm: {
        type: "",
        info: "",
        imgs: [],
      },
      rules: {
        type: [
          { required: true, message: "请选择反馈类型", trigger: "change" },
        ],
        info: [
          { required: true, message: "请输入反馈内容", trigger: "blur" },
        ]
      },
      courseId: null,
      buttonloading: false,
      uploadingFlag: null, //全局loading
    };
  },
  created() {
    this.getModule();
    if(this.$route.path.indexOf("/courseResource") != -1 && this.$route.path != "/courseResource"){
      this.showTypeItem = false
      this.courseId = this.$route.query.courseId
    } else {
      this.showTypeItem = true
    }
  },
  watch: {
    '$route'() {
      this.getModule()
      if(this.$route.path.indexOf("/courseResource") != -1 && this.$route.path != "/courseResource"){
        this.showTypeItem = false
        this.courseId = this.$route.query.courseId
      } else {
        this.showTypeItem = true
      }
    },
  },
  computed:{
    // ...mapState(['keepAlive']),
    ... mapState({
      feedbackTypeOptions: (state) => state.common.feedbackTypeList,
    }),
    key() {
      return this.$route.path
    }
  },
  methods: {
    getToken,
    //上传图片成功事件
    handleAvatarSuccess(res, file) {
      this.feedbackForm.imgs.push(res);
      this.uploadingFlag.close()
    },
    //上传封面之前事件
    beforeAvatarUpload(file) {
      if(this.feedbackForm.imgs.length == 9){
        this.$message.error("最多只能上传9张图片");
        this.$refs.fileUpload.handleRemove(file);
        return false
      }
      const isJPG = this.fileTypeList.indexOf(file.name.substr(file.name.lastIndexOf(".")+1,file.name.length).toLowerCase()) !== -1;
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 png, jpg 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      if(isJPG && isLt2M){
        this.uploadingFlag = this.$loading({
          lock: true,
          fullscreen: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      return isJPG && isLt2M; //&& isLt2M
    },
    //删除照片
    delFeedbackImg(index){//"确定删除该封面图片, 是否继续?"
      const h = this.$createElement;
      this.$confirm(h('p', null, [
            h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该图片？'),
            h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该图片，删除后，将无法恢复。')
          ]), "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
        type: "warning",
        customClass: "warningMessage"
      })
        .then(() => {
          this.feedbackForm.imgs.splice(index,1)
        })
        .catch(() => {});
    },
    //反馈信息
    handleFeedBack(){
      this.feedbackVisible = true
    },
    //弹窗关闭
    handleClose(){
      this.feedbackVisible = false
      this.$nextTick(() => {
        this.feedbackForm = {
          type: "",
          info: "",
          imgs: [],
        }
        this.buttonloading = false
        this.$refs.feedbackForm.clearValidate();
      })
    },
    //弹窗关闭
    handleSubmit(){
      this.$refs.feedbackForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            type: this.showTypeItem ? this.feedbackForm.type : "0",
            flag: '1',
            info: this.feedbackForm.info,
            imgs: this.feedbackForm.imgs,
            courseId: !this.showTypeItem ? this.courseId : ""
          };
          // params.id = this.courseId;
          this.$api.feedbackSave(params).then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "保存成功",
                type: "success",
                duration: 2000,
                onClose: () => {
                  this.handleClose();
                },
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleToHome(){
      this.$router.push('/')
    },
    getModule() {
      console.log(this.$route.matched)
      if(this.$route.matched[0].name == 'index'){
        this.module = "/"
      } else {
        this.module = this.$route.matched[0].path
      }
    },
    // 获取子组件的值
    getCollapse(data) {
      this.isCollapse = data;
    },
  },
  components: {Menu, TagView, Header, Main}
};
</script>

<style scoped lang="scss">
.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  .layout-title-wrap {
    cursor: pointer;
    width: 206px;
    background: #4B72F3;
    height: 55px;
    // display: flex;
    // align-items: center;
    // background: #3056D4;
    // height: 96px;
    // padding-top: 23px;

    .layout-title {
      // font-size: 20px;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      line-height: 2.5;
      text-align: center;
    }

    .layout-title-info {
      font-size: 12px;
      color: #9BAFEE;
      letter-spacing: -1px;
      line-height: 1.4;
    }
  }
  .layout-title-wrap-small{
    cursor: pointer;
    background: #3C62DE;
    height: 55px;
    // height: 60px;
    overflow: hidden;
  }

  .layout-aside {
    position: relative;
    overflow: hidden;
    transition: all 0.25s;

    .menu-wrap {
      position: absolute;
      // top: 120px;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow-y: scroll;
      background: #FFFFFF;
      // background: #597EF7;

      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }

  .layout-header {
    width: 100%;
    height: 115px!important;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-main-wrap{
    width: 100%;
    height: calc(100% - 115px);
    padding: 0;
    position: relative;
    overflow: hidden;
    .copyright{
      position: absolute;
      left: 0;
      bottom: 10px;
      right: 0;
      height: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      i,span{
        font-size: 0.73vw;
        color: rgba(0, 0, 0, 0.45);
      }
      & > span{
        margin-top: 10px;
      }
    }
    .layout-main{
      position: absolute;
      top: 0px;
      left: 10px;
      right: 0;
      bottom: 55px;
      overflow: hidden;
      .router-wrap{
        background: #F5F6FA;
        // padding: 12px;
        height: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: scroll;
        .el-backtop{
          right: 10px !important;
          bottom: 120px !important;
        }
      }
    }
  }

}

.feedback-icon{
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #597ef7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
  right: 10px;
  bottom: 175px;
}

/deep/.feedback-avatar-uploader{
  width: 119px;
  display: inline-block;
  vertical-align: top;
  .el-upload{
    width: 119px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    &:hover{
      border-color: #409EFF;
    }
  }
}

.feedback-avatar-uploader-info{
  width: 100%;
  height: 119px;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .feedback-avatar-uploader-icon{
    width: 28px;
    height: 28px;
    font-size: 28px;
    margin-bottom: 17px;
    line-height: 1;
  }
  .feedback-avatar-uploader-title{
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1;
  }
}

.choose-img-list{
  width: 100%;
  white-space: normal;
  .img-item{
    width: 121px;
    height: 121px;
    cursor: pointer;
    position: relative;
    margin-right: 12px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 12px;
    img{
      width: 100%;
      height: 100%;
      display: block;
    }
    .delete-icon{
      position: absolute;
      color: #f56c6c;
      font-size: 20px;
      right: -10px;
      top: -10px;
      cursor: pointer;
    }
  }
}

.feedback{
  &.el-textarea{
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    &:hover{
      border-color: #c0c4cc;
    }
  }
  /deep/.el-textarea__inner{
    margin-bottom: 18px;
    border: none;
  }
  /deep/.el-input__count{
    bottom: 5px;
    line-height: normal;
    &::before{
      content: "已输入 ";
    }
  }
}

//路由过渡动画
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.logo-img{
  display: block;
  width: 32px;
  height: 21px;
  margin-right: 4px;
  // width: 55px;
  // margin: 0 auto 10px;
}
.logo-img-small{
  display: block;
  width: 40px;
  // width: 55px;
  margin: 15px auto 0;
}
</style>
